import React, { FC } from "react"
import styles from "./picape-proud.module.scss"
import { Conditional, CTAButton, SectionElement } from "@/components"
import { dataMenuLabels, links } from "@/data/menu.data"
import scssStyles from "@utils/scssStyles"

const PicapeProudSection: FC = () => {
  const reference = dataMenuLabels().find((c) => c.slug === "off-road")

  return (
    <div className={styles.container}>
      <SectionElement
        id="photoSection"
        className={scssStyles([styles.box, styles.red])}
        navReference={reference}
      >
        <div className={styles.content}>
          <div className={styles.contentTitle}>
            <h2 className={styles.title}>
              Imponência<br/>
              Incomparável
            </h2>

            <p>
            Performance garantida e interior confortável. Tudo isso com um <br /> design robusto que cabe na sua garagem. Aproveite e compre já a sua Fiat Toro com condições especiais.
            </p>
          </div>

          <a target="_blank" href={links.picapes} >
            <div className={styles.universal_cta}>Aproveitar Ofertas</div>
          </a>

        </div>
      </SectionElement>
    </div>
  )
}

export default PicapeProudSection
