import { bannerImages } from "@/assets";
import CTAButton from "@/components/cta-button";
import ResponsiveLazyImage from "@/components/responsive-lazy-image";
import SectionElement from "@/components/section-element";
import { Context } from "@/context";
import { dataMenuLabels } from "@/data/menu.data";
import { FC, useContext } from "react";
import styles from "./award.module.scss";
import { StampsData } from "@/data/stamps-data";

const reference = dataMenuLabels().find((c) => c.slug === "selos");

export const AwardSection: FC = () => {
  const { state } = useContext(Context);
  return (
    <SectionElement
      id="Selos"
      className={styles.container}
      navReference={reference}
    >
      {StampsData.map((stamp) => {
        return (
          <div key={stamp.title} className={styles.stampBox}>
            <ResponsiveLazyImage
              className={styles.stamp}
              alt={stamp.alt}
              src={stamp.src}
              title={stamp.title}
            />
            <legend>{stamp.title}</legend>
          </div>
        );
      })}
    </SectionElement>
  );
};
